// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-accounting-jsx": () => import("./../../../src/pages/accounting.jsx" /* webpackChunkName: "component---src-pages-accounting-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-board-meeting-minutes-jsx": () => import("./../../../src/pages/board-meeting-minutes.jsx" /* webpackChunkName: "component---src-pages-board-meeting-minutes-jsx" */),
  "component---src-pages-code-security-audit-jsx": () => import("./../../../src/pages/code-security-audit.jsx" /* webpackChunkName: "component---src-pages-code-security-audit-jsx" */),
  "component---src-pages-community-jsx": () => import("./../../../src/pages/community.jsx" /* webpackChunkName: "component---src-pages-community-jsx" */),
  "component---src-pages-downloads-jsx": () => import("./../../../src/pages/downloads.jsx" /* webpackChunkName: "component---src-pages-downloads-jsx" */),
  "component---src-pages-getupdates-jsx": () => import("./../../../src/pages/getupdates.jsx" /* webpackChunkName: "component---src-pages-getupdates-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-meetup-jsx": () => import("./../../../src/pages/meetup.jsx" /* webpackChunkName: "component---src-pages-meetup-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-proposal-desc-jsx": () => import("./../../../src/pages/proposal_desc.jsx" /* webpackChunkName: "component---src-pages-proposal-desc-jsx" */),
  "component---src-pages-proposals-jsx": () => import("./../../../src/pages/proposals.jsx" /* webpackChunkName: "component---src-pages-proposals-jsx" */),
  "component---src-pages-stablecoin-proposals-jsx": () => import("./../../../src/pages/stablecoin_proposals.jsx" /* webpackChunkName: "component---src-pages-stablecoin-proposals-jsx" */),
  "component---src-pages-whitepaper-jsx": () => import("./../../../src/pages/whitepaper.jsx" /* webpackChunkName: "component---src-pages-whitepaper-jsx" */)
}

